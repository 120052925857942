import React, { memo, useState } from 'react'
import { useEffect } from 'react'
import { Drawer, Timeline, Spinner, Tabs, Divider, Grid, Card, Caption } from '@enterprise-ui/canvas-ui-react'
import { useAsset } from 'store'
import './AssetActivity.scss'
import AssetReportResolveForm from './AssetReportResolveForm'

export interface IAssetActivityValues {
  assetId: string
  activeTab?: 'assetHistory' | 'reportedIssues'
}

type IAssetActivityModalProps = {
  closeModal: () => void
  data?: IAssetActivityValues
}

const tabNames = {
  assetHistory: 'assetHistory',
  reportedIssues: 'reportedIssues',
}

const AssetActivityModal: React.FC<IAssetActivityModalProps> = ({ closeModal, data }) => {
  const {
    getAssetActivity,
    assetActivity,
    assetActivityIsLoading,
    assetReports,
    assetReportsIsLoading,
    resolveAssetReport,
  } = useAsset()
  const [selectedTab, setSelectedTab] = useState(data?.activeTab || tabNames.assetHistory)

  useEffect(() => {
    getAssetActivity(data?.assetId!!)
  }, [])

  const formatDateWithTimeZone = (dateString: string): string => {
    const date = new Date(dateString.replace(' ', 'T') + 'Z') // Append 'Z' to indicate UTC time
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).format(date)
  }

  return (
    <Drawer
      isVisible
      headingText={'Activity Log'}
      onRequestClose={() => closeModal()}
      lg={4}
      className="asset-activity-drawer"
    >
      <div>
        <Tabs
          activeTab={selectedTab}
          onTabSelect={(e: any, tab: any) => {
            setSelectedTab(tab.name)
          }}
          style={{ justifyContent: 'space-around' }}
          disableAdaptive
        >
          <Tabs.Item name={tabNames.assetHistory}>Asset History</Tabs.Item>
          <Tabs.Item name={tabNames.reportedIssues}>Reported Issues</Tabs.Item>
          <Divider />
          <Tabs.Content name={tabNames.assetHistory}>
            <Timeline>
              {assetActivityIsLoading ? (
                <Spinner data-test-id="load-indicator" className="activity-action-spinner" />
              ) : assetActivity && assetActivity.length > 0 ? (
                assetActivity.map((activity, index) => (
                  <Timeline.Item key={index}>
                    <strong>{formatDateWithTimeZone(activity.actionAt)}</strong>
                    <div className="hc-mr-lg">{activity.activityMessage}</div>
                  </Timeline.Item>
                ))
              ) : (
                <div>No activity found</div>
              )}
            </Timeline>
          </Tabs.Content>
          <Tabs.Content name={tabNames.reportedIssues}>
            {assetReportsIsLoading ? (
              <Spinner data-test-id="asset-report-load-indicator" className="asset-report-action-spinner" />
            ) : assetReports && assetReports.length > 0 ? (
              <div className="asset-reports-container hc-pa-normal">
                <Timeline>
                  {assetReports.map(report => (
                    <Timeline.Item id={report.id} key={report.id}>
                      <Card elevation={1} className="hc-mb-expanded">
                        <Grid.Container align="flex-end" justify="space-between" className="hc-pa-normal">
                          <Grid.Item xs={report.resolvedByUserName ? 12 : 7} className="hc-pr-none">
                            <Caption
                              below={`Reported on ${report.createdAt.toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' })}, ${report.createdAt.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: true })}`}
                            >
                              {`Submitted by ${report.createdByUserName}`}
                            </Caption>
                            <p className="hc-clr-grey01 hc-mb-none hc-mt-dense">{`"${report.reportTitle}"`}</p>
                            <p className="hc-clr-grey02 hc-fs-xs">{report.reportDescription}</p>
                            {report.resolvedAt && (
                              <>
                                <Caption
                                  below={`Resolved on ${report.resolvedAt.toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' })}, ${report.resolvedAt.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: true })}`}
                                  className="hc-mt-dense"
                                >
                                  {`Resolved by ${report.resolvedByUserName}`}
                                </Caption>
                                {report.resolveComments && (
                                  <p className="hc-clr-grey02 hc-fs-xs">Resolve Comments: {report.resolveComments}</p>
                                )}
                              </>
                            )}
                          </Grid.Item>
                          <AssetReportResolveForm report={report} resolveAssetReport={resolveAssetReport} />
                        </Grid.Container>
                      </Card>
                    </Timeline.Item>
                  ))}
                </Timeline>
              </div>
            ) : (
              <div>No asset reports found</div>
            )}
          </Tabs.Content>
        </Tabs>
      </div>
    </Drawer>
  )
}

export default memo(AssetActivityModal)
