import React from 'react'
import { Heading, Grid, Card, Spinner, FormField } from '@enterprise-ui/canvas-ui-react'
import DownloadAssetButton from 'components/buttons/DownloadAssetButton'
import HistoricalLink from 'components/shared/HistoricalLink'
import { filter, head } from 'lodash'
import AddAssetToCollectionButton from 'components/buttons/AddAssetToCollectionButton'
import GalleryFileImage from 'components/images/GalleryFileImage'
import { useEnv } from '@praxis/component-runtime-env'
import { IAsset } from 'store'
import styles from './AssetResult.module.scss'
import EnterpriseIcon, { FlagFilledIcon } from '@enterprise-ui/icons'
import { AssetReportView } from '@galleryjs/api-digital-assets'

type AssetResultWrapperProps = {
  children: any
  asset?: IAsset
}

export const AssetResultWrapper: React.FC<AssetResultWrapperProps> = ({ children, asset }) => {
  const pathname = asset ? `/asset/${asset.id}` : undefined

  return (
    <Grid.Item className={`${styles.asset} hc-pv-md hc-ph-normal`} data-test-id="asset-result">
      <HistoricalLink pathname={pathname}>
        <Card elevation={1} className={styles.result}>
          {children}
        </Card>
      </HistoricalLink>
    </Grid.Item>
  )
}
type AssetResultProps = {
  asset: IAsset
  checkedItems: string[]
  onClick: (e: any) => void
  isCheckable: boolean
}

const AssetResult: React.FC<AssetResultProps> = ({ asset, checkedItems, onClick, isCheckable }) => {
  const env = useEnv()
  const heroImagePriority = env.heroImagePriority
  if (!asset) {
    return (
      <AssetResultWrapper>
        <Grid.Container align="center" justify="center">
          <Grid.Item>
            <Spinner data-test-id="load-indicator" />
          </Grid.Item>
        </Grid.Container>
      </AssetResultWrapper>
    )
  }
  const { files: assetFiles, name, id, assetReports } = asset
  const files = Array.from(assetFiles || []).map(file => ({ ...file, fileSources: Array.from(file.fileSources || []) }))
  const heroImageFiles = filter(files, 'heroImage')
  heroImageFiles.sort(
    (a, b) =>
      (heroImagePriority[a.classification] || heroImagePriority.default) -
      (heroImagePriority[b.classification] || heroImagePriority.default)
  )
  // using double bang to satisfy typescript
  const heroImageFile = head(heroImageFiles)!!
  const checked: boolean = checkedItems.includes(id)
  const hasUnresolvedReports = Array.from(assetReports || [])?.some(
    (report: AssetReportView & { resolvedBy?: string }) => !report.resolvedBy
  )

  return (
    <AssetResultWrapper asset={asset}>
      {isCheckable && (
        <div
          className={styles['asset__checkbox']}
          data-test-id="asset-checkbox"
          onClick={(e: React.MouseEvent<HTMLDivElement>) => onClick(e)}
        >
          <FormField
            type="checkbox"
            data-testid={`asset-checkbox-${asset.id}`}
            checked={checked}
            className="hc-mt-dense"
          />
        </div>
      )}
      {hasUnresolvedReports && (
        <div className={`${styles['asset__reportflag']} hc-pa-dense`} data-test-id="asset-reportflag">
          <EnterpriseIcon icon={FlagFilledIcon} style={{ color: 'orangered' }} />
        </div>
      )}

      <Grid.Container spacing={'dense'} padding={'none'}>
        <GalleryFileImage
          file={asset.heroImageFile !== undefined ? asset.heroImageFile : heroImageFile}
          data-test-id="asset-result-image"
          className={styles['asset__thumbnail']}
          width="100%"
          type="contain"
          src={
            asset.heroImageFile?.fileSources[0].accessUrl !== undefined
              ? asset.heroImageFile?.fileSources[0].accessUrl
              : heroImageFile?.fileSources[0].accessUrl
          }
          alt={asset.heroImageFile?.fileSources[0].fileName!}
        />
        <Grid.Item xs={12} className="hc-mb-none hc-pt-sm">
          <Grid.Container className="hc-bg-grey07">
            <Grid.Item xs={9}>
              <Heading
                size={3}
                className={`${styles['line-limit-2']} hc-fs-sm name hc-pl-md`}
                data-test-id="asset-result-name"
              >
                {name || ''}
              </Heading>
            </Grid.Item>
            <Grid.Item>
              <Grid.Container>
                <div className="hc-pt-sm">
                  <DownloadAssetButton assetIds={[id]} isButton={false} />
                </div>
                <div className="hc-pt-sm">
                  <AddAssetToCollectionButton assets={[asset]} isButton={false} />
                </div>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
    </AssetResultWrapper>
  )
}
export default AssetResult
