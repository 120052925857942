import { Grid, FormField, Spinner, Tooltip, Anchor } from '@enterprise-ui/canvas-ui-react'
import { useModal, useSearch, useTaxonomy } from 'store'
import React, { useEffect } from 'react'
import styles from './SearchBar.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { searchInputSanitizer, sanitize } from 'utils/validation'
import { MODAL_TYPES } from 'config'

type SearchBarContentProps = {
  className: string
  id?: string
  showSelect: boolean
}

const SearchBar: React.FC<SearchBarContentProps> = ({ className, showSelect, id = null }) => {
  const { tabs } = useTaxonomy()
  const { saveDataAndTypeToStore } = useModal()
  const {
    dispatchSearch,
    currentSearchValue,
    setSearchValue,
    currentSearchTab,
    setSearchTab,
    searchSize,
    activateExactSearch,
    activateSelfAssetCreatedSearch,
    activateSearchChange,
    exactSearch,
    selfAssetToggle,
    clearStore,
    searchCriteria,
  } = useSearch()
  const submitSearchResults = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    clearStore()
    const sanitizedSearchValue = sanitize(currentSearchValue, searchInputSanitizer)
    const filters = searchCriteria.f || {}
    // Need to take all text
    if (currentSearchValue !== sanitizedSearchValue) {
      // Removing the warning since it's confusing people. Will leave as a comment for awhile in case we need to bring it back.
      dispatchSearch({
        q: sanitizedSearchValue,
        p: 0,
        f: filters,
        t: currentSearchTab,
        size: searchSize,
      })
      setSearchValue(sanitizedSearchValue)
    } else {
      dispatchSearch({
        q: currentSearchValue,
        p: 0,
        f: filters,
        t: currentSearchTab,
        size: searchSize,
      })
    }
  }

  const handleChange = (event: any) => {
    setSearchValue(event.target.value)
  }

  // search tab logic
  const trackAndSetActiveTab = (tab: string) => {
    setSearchTab(tab)
  }

  useEffect(() => {
    setSearchTab('All')
  }, [])

  if (tabs.length === 0) {
    return (
      <Grid.Container className={styles.searchBarContainer}>
        <Spinner size="dense" />
      </Grid.Container>
    )
  }

  return (
    <Grid.Container className={`${styles.searchBarContainer} ${styles[className]}`} id={id}>
      <Grid.Item xs={12} className={styles.searchWrapper}>
        <Grid.Container>
          {showSelect && (
            <Grid.Item className={styles.searchTaxonomyContainer}>
              <FormField
                type="select"
                highlight={false}
                id="taxonomy-selector"
                data-testid="search-taxonomy-select"
                className={`${styles.searchTaxonomySelect} C-Mode--light`}
                value={currentSearchTab}
                onChange={(e: any) => {
                  trackAndSetActiveTab(tabs[e.currentTarget.value])
                }}
                options={tabs.map(tab => {
                  return {
                    value: tab,
                    label: tab,
                    disabled: false,
                  }
                })}
              />
            </Grid.Item>
          )}
          <Grid.Item className={styles[className]}>
            <span className={styles.searchInput}>
              <form onSubmit={e => submitSearchResults(e)}>
                <div
                  className={
                    currentSearchValue ? styles['search-icon'] : `${styles['search-icon']}  ${styles['disable-search']}`
                  }
                  data-testid="search-icon"
                  onClick={e => submitSearchResults(e)}
                >
                  <FontAwesomeIcon icon="search" color="#444444" size="lg" />
                </div>

                <input
                  type="text"
                  data-testid="search-input"
                  placeholder="Search Gallery"
                  className={styles.search}
                  autoFocus
                  value={currentSearchValue}
                  onChange={e => handleChange(e)}
                />
              </form>
            </span>
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
      {showSelect && (
        <Grid.Item xs={12} className={styles.searchToolsWrapper}>
          <Grid.Container>
            <Grid.Item className={styles.searchToolsContainer} xs={12}>
              <Grid.Container className={styles.searchTools} justify="space-between" align="center">
                <Grid.Item xs={9} justify="space-between">
                  <Grid.Item className={styles.exactSearch}>
                    {' '}
                    <FormField
                      type="toggle"
                      size="dense"
                      id="exact-search-toggle"
                      label="Use exact search"
                      data-testid="exact-search-toggle"
                      className={`${styles.searchToolToggle}  ${
                        className === 'hero-search' ? 'C-Mode--dark' : 'C-Mode--light'
                      }`}
                      value={exactSearch}
                      onChange={(event: any) => {
                        activateSearchChange(true)
                        activateExactSearch(event.currentTarget.checked)
                      }}
                    />
                    <Tooltip
                      className={`${styles.searchToolTip} ${
                        className === 'hero-search' ? 'C-Mode--dark' : 'C-Mode--light'
                      }`}
                      content={`Gallery will only return search results with the exact phrase you entered.
                  For example:
                  "Yellow"
                   will only return items that absolutely contain the word Yellow.`}
                      location="bottom"
                    >
                      <FontAwesomeIcon
                        icon="info-circle"
                        color={className === 'hero-search' ? '#fff' : '#444'}
                        size="lg"
                      />
                    </Tooltip>
                  </Grid.Item>

                  <Grid.Item className={`hc-pl-xl ${styles.exactSearch}`}>
                    {' '}
                    <FormField
                      type="toggle"
                      size="dense"
                      id="assets-created-me-toggle"
                      label="Assets created by me"
                      data-testid="assets-created-me-toggle"
                      className={`${styles.searchToolToggle}  ${
                        className === 'hero-search' ? 'C-Mode--dark' : 'C-Mode--light'
                      }`}
                      value={selfAssetToggle}
                      onChange={(event: any) => {
                        activateSearchChange(true)
                        activateSelfAssetCreatedSearch(event.currentTarget.checked)
                      }}
                    />
                    <Tooltip
                      className={`${styles.searchToolTip} ${
                        className === 'hero-search' ? 'C-Mode--dark' : 'C-Mode--light'
                      }`}
                      content={`Gallery will return search results with only the assets created by me.`}
                      location="bottom"
                    >
                      <FontAwesomeIcon
                        icon="info-circle"
                        color={className === 'hero-search' ? '#fff' : '#444'}
                        size="lg"
                      />
                    </Tooltip>
                  </Grid.Item>
                </Grid.Item>
                <Grid.Item className={`${className === 'hero-search' ? styles.listSearch : ''}`}>
                  {' '}
                  <Anchor
                    className={styles.listSearchLink}
                    data-test-id="create-new-collection-anchor"
                    onClick={() => {
                      saveDataAndTypeToStore(MODAL_TYPES.ID_LIST_SEARCH, { searchTerms: '' })
                    }}
                  >
                    ID list search
                  </Anchor>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      )}
    </Grid.Container>
  )
}

export default SearchBar
