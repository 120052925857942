import React from 'react'
import { useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
// import 'canvas-ui-css-marquette'
import CreateCollectionModal from 'components/modals/CreateCollectionModal/CreateCollectionModal'
import CreateAssetModal from 'components/modals/CreateAssetModal/CreateAssetModal'
import ConfirmDialog from 'components/dialogs/ConfirmDialog'
import { MODAL_TYPES } from 'config/constants'
import DeleteModal from 'components/modals/DeleteModal/DeleteModal'
import EditAssetModal from 'components/modals/EditAssetModal/EditAssetModal'
import AssetCollectionModal from 'components/modals/AssetCollectionModal/AssetCollectionModal'
import EditAssetManagerModal from 'components/modals/EditAssetManagerModal/EditAssetManagerModal'
import EditCollectionManagerModal from 'components/modals/EditCollectionManagerModal/EditCollectionManagerModal'
import CollageLightboxModal from 'components/modals/CollageLightboxModal/CollageLightboxModal'
import HelpModal from 'components/modals/HelpModal/HelpModal'
import { useDialog, useModal } from 'store'

import { setModalType, RootState } from 'store'
import { useSelector, useDispatch } from 'react-redux'
import EditCollectionModal from 'components/modals/EditCollectionModal/EditCollectionModal'
import IdListSearchModal from 'components/modals/IdListSearchModal/IdListSearchModal'
import AssetActivityModal from 'components/modals/AssetActivityModal/AssetActivityModal'
import BulkCreateAssetModal from 'components/modals/CreateAssetModal/BulkCreateAssetModal'
import { useEnv } from '@praxis/component-runtime-env'
import AssetReportCreateModal from 'components/modals/AssetReportCreateModal/AssetReportCreateModal'

const useModalWatcher = () => {
  const location = useLocation()
  const modalFromSlice = useSelector((state: RootState) => state.modal.value)
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  useEffect(() => {
    const type = searchParams.get('modal')
    if (type) {
      dispatch(setModalType(type))
    } else if (modalFromSlice.type) {
      dispatch(setModalType(''))
    }
  }, [location, searchParams])
}

const ModalSetup: React.FC = () => {
  useModalWatcher()

  const modalFromSlice = useSelector((state: RootState) => state.modal.value)
  const { bulkAssetCreateFeatureFlag } = useEnv()
  const { dialogData, openDialog } = useDialog()
  const [searchParams, setSearchParams] = useSearchParams()
  const { saveDataAndTypeToStore } = useModal()

  // Remove the hash from the URL to close the modal
  const closeModal = () => {
    searchParams.delete('modal')
    setSearchParams(searchParams)
  }

  const assetReportModalOnClose = (isFromSuccessCallBack?: boolean) => {
    if (isFromSuccessCallBack) {
      const modalData = {
        assetId: modalFromSlice?.data?.assetId,
        activeTab: 'reportedIssues',
      }
      saveDataAndTypeToStore(MODAL_TYPES.ASSET_ACTIVITY, modalData)
    } else {
      closeModal()
    }
  }

  const viewDeleteModal =
    modalFromSlice.type === MODAL_TYPES.DELETE_FILE ||
    modalFromSlice.type === MODAL_TYPES.DELETE_ASSET ||
    modalFromSlice.type === MODAL_TYPES.DELETE_COLLECTION

  return (
    <>
      {dialogData?.isVisible && <ConfirmDialog {...{ dialogData, setDialogData: openDialog }} />}

      {viewDeleteModal && <DeleteModal data={modalFromSlice?.data} closeModal={() => closeModal()} />}

      {modalFromSlice?.type === MODAL_TYPES.ADD_COLLECTION && (
        <AssetCollectionModal data={modalFromSlice?.data} closeModal={() => closeModal()} />
      )}

      {modalFromSlice?.type === MODAL_TYPES.CREATE_COLLECTION && (
        <CreateCollectionModal data={modalFromSlice?.data} closeModal={() => closeModal()} />
      )}

      {modalFromSlice?.type === MODAL_TYPES.ADD_ASSET && (
        <>
          {bulkAssetCreateFeatureFlag ? (
            <BulkCreateAssetModal data={modalFromSlice?.data} closeModal={() => closeModal()} />
          ) : (
            <CreateAssetModal data={modalFromSlice?.data} closeModal={() => closeModal()} />
          )}
        </>
      )}

      {modalFromSlice?.type === MODAL_TYPES.EDIT_ASSET && (
        <EditAssetModal data={modalFromSlice?.data} closeModal={() => closeModal()} />
      )}

      {modalFromSlice?.type === MODAL_TYPES.EDIT_ASSET_USER && (
        <EditAssetManagerModal data={modalFromSlice?.data} closeModal={() => closeModal()} />
      )}

      {modalFromSlice?.type === MODAL_TYPES.EDIT_COLLECTION_USER && (
        <EditCollectionManagerModal data={modalFromSlice?.data} closeModal={() => closeModal()} />
      )}

      {modalFromSlice?.type === MODAL_TYPES.COLLAGE_LIGHTBOX && (
        <CollageLightboxModal data={modalFromSlice?.data} closeModal={() => closeModal()} />
      )}

      {modalFromSlice?.type === MODAL_TYPES.EDIT_COLLECTION && (
        <EditCollectionModal data={modalFromSlice?.data} closeModal={() => closeModal()} />
      )}

      {modalFromSlice?.type === MODAL_TYPES.HELP && <HelpModal closeModal={() => closeModal()} />}

      {modalFromSlice?.type === MODAL_TYPES.ID_LIST_SEARCH && <IdListSearchModal closeModal={() => closeModal()} />}

      {modalFromSlice?.type === MODAL_TYPES.ASSET_ACTIVITY && (
        <AssetActivityModal data={modalFromSlice?.data} closeModal={() => closeModal()} />
      )}

      {modalFromSlice?.type === MODAL_TYPES.ASSET_REPORT && (
        <AssetReportCreateModal closeModal={assetReportModalOnClose} data={modalFromSlice?.data} />
      )}
    </>
  )
}

export default ModalSetup
