import React from 'react'
import { Button, Form, Grid, Modal, Spinner } from '@enterprise-ui/canvas-ui-react'
import { useFormik } from 'formik'
import { AssetReportCreate } from '@galleryjs/api-digital-assets'
import { useAsset } from 'store'
import { assetReportFields, assetReportOptions } from './constants'

const AssetReportCreateModal = ({ closeModal, data }: { closeModal: Function; data: any }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [selectedOption, setSelectedOption] = React.useState(assetReportOptions[0].value)
  const [showOtherOptionInput, setShowOtherOptionInput] = React.useState(false)
  const [showCreateReportError, setShowCreateReportError] = React.useState(false)
  const { createAssetReport } = useAsset()

  const initialValues: AssetReportCreate = {
    assetId: data?.assetId,
    reportTitle: assetReportOptions[0].value,
    reportDescription: '',
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values: AssetReportCreate) => {
      setIsSubmitting(true)
      createAssetReport(
        values,
        () => {
          closeModal(true)
        },
        () => {
          setShowCreateReportError(true)
        }
      )
    },
    validate: (values: AssetReportCreate) => {
      const errors: any = {}
      if (!values.assetId.trim()) {
        errors.assetId = 'Asset ID is required'
      }
      if (values.reportTitle.trim() === '') {
        errors.reportTitle = 'Required'
      }
      if (values.reportDescription.trim() === '') {
        errors.reportDescription = 'Required'
      }
      return errors
    },
  })

  return (
    <Modal
      isVisible
      size="dense"
      onRefuse={() => {
        closeModal()
      }}
      onApproveModal={() => {}}
    >
      <div className="hc-pa-expanded">
        <div>
          <h1 className="hc-fs-lg hc-pt-sm">Report asset</h1>
        </div>
        <div className="hc-mt-normal">
          <Form.Field
            type="radio"
            id={assetReportFields.reportTitle}
            label="What is the issue?"
            value={selectedOption}
            required
            options={assetReportOptions}
            onUpdate={(_: any, val: any) => {
              const isOptionOther = val === 'Other'
              setSelectedOption(val)
              setShowOtherOptionInput(isOptionOther)
              formik.setFieldValue(assetReportFields.reportTitle, isOptionOther ? '' : val)
            }}
          />
          {showOtherOptionInput && (
            <Form.Field
              type="text"
              placeholder="Enter issue (required)"
              error={formik.touched.reportTitle && !!formik.errors.reportTitle}
              errorText={formik.errors.reportTitle}
              onBlur={() => formik.setFieldTouched(assetReportFields.reportTitle, true)}
              onChange={(e: any) => {
                formik.setFieldValue(assetReportFields.reportTitle, e.target.value)
              }}
            />
          )}
        </div>
        <div className="hc-mt-expanded">
          <Form.Field
            type="textarea"
            id={assetReportFields.reportDescription}
            label="Description"
            error={formik.touched.reportDescription && !!formik.errors.reportDescription}
            errorText={formik.errors.reportDescription}
            required
            value={formik.values.reportDescription}
            onBlur={() => formik.setFieldTouched(assetReportFields.reportDescription, true)}
            onChange={(e: any) => {
              formik.setFieldValue(assetReportFields.reportDescription, e.target.value)
            }}
          />
        </div>
        {showCreateReportError && (
          <div className="hc-mt-normal hc-clr-error hc-fs-sm">
            An error occurred while creating the report. Please try again.
          </div>
        )}
        <Grid.Container justify="flex-end" className="hc-mt-normal">
          <Grid.Item>
            <Button onClick={() => closeModal()} type="secondary">
              Cancel
            </Button>
          </Grid.Item>
          <Grid.Item>
            <Button
              disabled={isSubmitting || !formik.dirty || !formik.isValid}
              onClick={() => formik.handleSubmit()}
              type="primary"
            >
              {isSubmitting && <Spinner size="dense" />}
              {!isSubmitting && 'Submit'}
            </Button>
          </Grid.Item>
        </Grid.Container>
      </div>
    </Modal>
  )
}

export default AssetReportCreateModal
