import React from 'react'
import './FilterContainer.scss'
import { useSearch } from 'store'
import FilterItem from './FilterItem'
import '@enterprise-ui/canvas-ui-css'
import { startOfMonth } from 'date-fns'
import '@enterprise-ui/canvas-ui-css-datepicker'
import { useEnv } from '@praxis/component-runtime-env'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { IFilterAction, IFilterItemUI, IFilterValueUI, ICustomFilterValue } from 'store'
import { Heading, Grid, Button, Spinner, Card, ExpandableSection, Avatar } from '@enterprise-ui/canvas-ui-react'

const FilterContainer: React.FC = () => {
  const numberOfStartingExpandedSections = 2
  const { searchFilters, searchFiltersAreLoading, updateSearchCriteriaFilters, currentFilterChips, selectFilter } =
    useSearch()
  const { customSearchFilters, dateFilterFeatureFlag } = useEnv()
  const currentDate = new Date()
  const firstOfMonth = startOfMonth(currentDate)
  const pastDate = new Date()
  pastDate.setFullYear(currentDate.getFullYear() - 10)
  pastDate.setMonth(0)
  pastDate.setDate(1)

  const isCurrentFilterChipsNotEmpty = () => {
    return (
      typeof currentFilterChips === 'object' &&
      currentFilterChips !== null &&
      Object.keys(currentFilterChips).length > 0
    )
  }

  // creating the filter items for customSearchFilters
  const createCustomFilterUiItems = () => {
    let customFilterItems: IFilterItemUI[] = []
    if (customSearchFilters) {
      Object.keys(customSearchFilters).forEach((key: string) => {
        const filterKeyUI = key
        let filterValueUI: IFilterValueUI[] = []
        customSearchFilters[key].forEach((value: ICustomFilterValue) => {
          filterValueUI.push({
            filterValue: value,
            selected: false,
          })
        })
        customFilterItems.push({ filterKeyUI: filterKeyUI, filterValueUI: filterValueUI })
      })
    }
    return customFilterItems
  }

  const customFilterUiItems = createCustomFilterUiItems()
  const handleDateRangeUpdate = (id: string, value: string) => {
    selectFilter(id, value)
  }

  return (
    <>
      <Grid.Container className="hc-pa-normal hc-bg-white filter-heading" spacing="none" justify="space-between">
        <Grid.Item>
          <Heading size={4} data-test-id="filter-header" className="filter-header">
            Filter Search
          </Heading>
        </Grid.Item>
        <Grid.Item>
          <Button
            className="clear-filters-button"
            data-testid="clear-filters-button"
            onClick={() => updateSearchCriteriaFilters(IFilterAction.RemoveAll)}
            type="primary"
            disabled={
              !isCurrentFilterChipsNotEmpty() ||
              (Object.keys(currentFilterChips).length === 1 &&
                'original_upload_date' in currentFilterChips &&
                !(currentFilterChips.original_upload_date as any[])[0]['startDate'])
            }
          >
            CLEAR ALL
          </Button>
        </Grid.Item>
      </Grid.Container>
      {dateFilterFeatureFlag && (
        <div className={'date-filter-container'}>
          <Card elevation={0} className="hc-pr-normal">
            <div className="hc-pa-normal">
              <Grid.Container direction="column" spacing="normal">
                {
                  <ExpandableSection startExpanded={true} className="datepicker-filter-drawer">
                    {
                      <Grid.Container spacing="dense">
                        <Grid.Item>
                          <p data-test-id="filter-category" className="filter-category-title">
                            Original Upload Date
                          </p>
                        </Grid.Item>
                        {isCurrentFilterChipsNotEmpty() &&
                          'original_upload_date' in currentFilterChips &&
                          (currentFilterChips.original_upload_date as any[])[0]['startDate'] !== undefined && (
                            <Grid.Item>
                              <Avatar
                                aria-label="number of filters"
                                data-testid="filter-avatar"
                                className="filter-avatar"
                              >
                                1
                              </Avatar>
                            </Grid.Item>
                          )}
                      </Grid.Container>
                    }
                    <ExpandableSection.Content>
                      <DatePicker
                        key="custom-range-key"
                        id="original_upload_date"
                        data-testid="custom-date-range"
                        type="gregorian-range"
                        label="Date Range"
                        placeholder="Select date range"
                        disableDates={{ future: true }}
                        quickSelectRange={[
                          'lastMonth',
                          {
                            endDate: currentDate,
                            label: 'This Month',
                            startDate: firstOfMonth,
                          },
                          'ytd',
                          {
                            endDate: currentDate,
                            label: 'Custom Date range',
                            startDate: pastDate,
                          },
                        ]}
                        onUpdate={handleDateRangeUpdate}
                        value={
                          isCurrentFilterChipsNotEmpty() &&
                          'original_upload_date' in currentFilterChips &&
                          (currentFilterChips.original_upload_date as any[])[0]['startDate'] !== undefined
                            ? currentFilterChips?.original_upload_date[0]
                            : null
                        }
                      />
                    </ExpandableSection.Content>
                  </ExpandableSection>
                }
              </Grid.Container>
            </div>
          </Card>
        </div>
      )}
      <div className={'filter-container'}>
        {searchFiltersAreLoading && (
          <Grid.Container data-testid="load-indicator-container" justify="center" className="hc-pt-xl">
            <Spinner data-test-id="load-indicator" />
          </Grid.Container>
        )}
        {searchFilters.length > 0 &&
          !searchFiltersAreLoading &&
          searchFilters.map((filterItemUI: IFilterItemUI, i: number) => (
            <FilterItem
              key={`${filterItemUI.filterKeyUI}-${filterItemUI.filterValueUI.length}`}
              filterItemUI={filterItemUI}
              startExpanded={i < numberOfStartingExpandedSections ? true : false}
              showInput={true}
            />
          ))}
        {!searchFiltersAreLoading &&
          customFilterUiItems &&
          customFilterUiItems.map((filterItemUI: IFilterItemUI) => (
            <FilterItem
              key={filterItemUI.filterKeyUI}
              filterItemUI={filterItemUI}
              startExpanded={false}
              showInput={false}
            />
          ))}
      </div>
    </>
  )
}

export default FilterContainer
