import React, { useState } from 'react'
import { Button, Form, Grid, Spinner } from '@enterprise-ui/canvas-ui-react'
import { AssetReportResolve, AssetReportView } from '@galleryjs/api-digital-assets'

const AssetReportResolveForm = ({
  report,
  resolveAssetReport,
}: {
  report: AssetReportView
  resolveAssetReport: (resolveAssetReportPayload: AssetReportResolve, onSuccess: Function, onError: Function) => void
}) => {
  const [showCommentBox, setShowCommentBox] = useState(false)
  const [resolveComments, setResolveComments] = useState('')
  const [showError, setShowError] = useState(false)
  const [resolveCommentsTouched, setResolveCommentsTouched] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  return (
    <>
      {!report.resolvedByUserName && (
        <>
          {!showCommentBox ? (
            <Grid.Item>
              <Button
                type="secondary"
                onClick={() => {
                  setShowCommentBox(true)
                }}
              >
                Mark as resolved
              </Button>
            </Grid.Item>
          ) : (
            <Grid.Item xs={12}>
              <Grid.Container>
                <Grid.Item xs={9}>
                  {showError && (
                    <div className="hc-mt-normal hc-clr-error hc-fs-sm">
                      An error occurred while resolving the report. Please try again.
                    </div>
                  )}
                  <Form.Field
                    type="textarea"
                    value={resolveComments}
                    onBlur={() => {
                      setResolveCommentsTouched(true)
                    }}
                    error={resolveCommentsTouched && !resolveComments}
                    errorText="Required"
                    placeholder="Enter resolve comments (required)"
                    onChange={(e: any) => {
                      setResolveComments(e.target.value)
                    }}
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Grid.Container>
                    <Grid.Item xs={12}>
                      <Button
                        type="primary"
                        fullWidth
                        disabled={!resolveComments || isSubmitting}
                        onClick={() => {
                          setIsSubmitting(true)
                          resolveAssetReport(
                            { assetReportId: report.id, resolveComments },
                            () => {
                              setShowCommentBox(false)
                              setIsSubmitting(false)
                            },
                            () => {
                              setShowError(true)
                              setIsSubmitting(false)
                            }
                          )
                        }}
                      >
                        {isSubmitting && <Spinner size="dense" />}
                        {!isSubmitting && 'Resolve'}
                      </Button>
                    </Grid.Item>
                    <Grid.Item xs={12}>
                      <Button
                        type="secondary"
                        fullWidth
                        disabled={isSubmitting}
                        onClick={() => {
                          setShowCommentBox(false)
                          setResolveCommentsTouched(false)
                          setShowError(false)
                          setResolveComments('')
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          )}
        </>
      )}
    </>
  )
}

export default React.memo(AssetReportResolveForm)
