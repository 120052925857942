export const assetReportOptions = [
  { value: 'This asset is not usable', label: 'This asset is not usable' },
  { value: 'This asset is missing files/textures', label: 'This asset is missing files/textures' },
  { value: 'This asset is outdated', label: 'This asset is outdated' },
  { value: 'The asset is put together incorrectly', label: 'The asset is put together incorrectly' },
  { value: 'Other', label: 'Other' },
]
export const assetReportFields = {
  reportTitle: 'reportTitle',
  reportDescription: 'reportDescription',
}
