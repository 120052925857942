import { createSlice, createReducer } from '@reduxjs/toolkit'
import { ISearchState, ISearchPageAssets, ISearchFilters, ISearchCriteria } from './types'

const initialSearchCriteria = {
  q: '',
  p: 0,
  f: {},
  t: '',
  size: 0,
  sort: [],
}

const initialState: ISearchState = {
  searchPageAssets: {
    page: 0,
    assets: [],
    total: 0,
    paginationIsLoading: false,
  },
  exactSearch: true,
  selfAssetToggle: false,
  searchAPIError: false,
  currentSearchValue: '',
  currentSearchTab: '',
  searchFilters: {
    loading: false,
    filterValues: [],
  },
  searchCriteria: initialSearchCriteria,
  searchChange: false,
}

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchCriteriaReducer(state, action) {
      const newSearchCriteria: ISearchCriteria = action.payload
      state.searchCriteria.q = newSearchCriteria?.q
      state.searchCriteria.p = newSearchCriteria.p
      state.searchCriteria.f = newSearchCriteria?.f
      state.searchCriteria.t = newSearchCriteria?.t
      state.searchCriteria.size = newSearchCriteria?.size
      state.searchCriteria.sort = newSearchCriteria?.sort
    },
    clearSearchCriteria(state, action) {
      state.searchCriteria = initialSearchCriteria
    },
    setSearchAPIError(state, action) {
      state.searchAPIError = action.payload
    },
    searchAssetsReducer(state, action) {},
    setSearchPageAssets(state, action) {
      searchPageAssetsReducer(state.searchPageAssets, action)
    },
    appendSearchPageAssets(state, action) {
      searchPageAssetsReducer(state.searchPageAssets, action)
    },
    setSearchPageAssetsPaginationIsLoading(state, action) {
      searchPageAssetsReducer(state.searchPageAssets, action)
    },
    clearSearchPageAssets(state, action) {
      searchPageAssetsReducer(state.searchPageAssets, action)
    },
    setCurrentSearchValue(state, action) {
      state.currentSearchValue = action.payload
    },
    setCurrentSearchTab(state, action) {
      state.currentSearchTab = action.payload
    },
    setExactSearch(state, action) {
      state.exactSearch = action.payload
    },
    setSelfAssetToggle(state, action) {
      state.selfAssetToggle = action.payload
    },
    setSearchChange(state, action) {
      state.searchChange = action.payload
    },
    getFiltersReducer(state, action) {},
    setFilters(state, action) {
      filtersReducer(state.searchFilters, action)
    },
    setFiltersAreLoading(state, action) {
      filtersReducer(state.searchFilters, action)
    },
  },
})

export const {
  setSearchCriteriaReducer,
  clearSearchCriteria,
  searchAssetsReducer,
  setSearchPageAssets,
  appendSearchPageAssets,
  clearSearchPageAssets,
  setSearchPageAssetsPaginationIsLoading,
  setCurrentSearchValue,
  setCurrentSearchTab,
  getFiltersReducer,
  setFilters,
  setFiltersAreLoading,
  setSearchAPIError,
  setExactSearch,
  setSelfAssetToggle,
  setSearchChange,
} = searchSlice.actions

const searchPageAssetsReducer = createReducer<ISearchPageAssets>(
  { page: 0, assets: [], total: 0, paginationIsLoading: false },
  builder => {
    builder
      .addCase(setSearchPageAssets, (state, action) => {
        state.page = 0
        state.assets = action.payload.assets
        state.total = action.payload.total
        state.paginationIsLoading = false
        state.sort = action.payload.sort
      })
      .addCase(appendSearchPageAssets, (state, action) => {
        state.page = action.payload.page
        state.assets = state.assets.concat(action.payload.assets)
        state.total = action.payload.total
        state.paginationIsLoading = false
        state.sort = action.payload.sort
      })
      .addCase(setSearchPageAssetsPaginationIsLoading, (state, action) => {
        state.paginationIsLoading = action.payload
      })
      .addCase(clearSearchPageAssets, (state, action) => {
        state.page = 0
        state.assets = []
        state.total = 0
        state.paginationIsLoading = true
        state.sort = undefined
      })
  }
)

const filtersReducer = createReducer<ISearchFilters>({ loading: false, filterValues: [] }, builder => {
  builder
    .addCase(setFilters, (state, action) => {
      state.loading = false
      state.filterValues = action.payload
    })
    .addCase(setFiltersAreLoading, state => {
      state.loading = true
      state.filterValues = []
    })
})

export default searchSlice.reducer
