import dayjs from 'dayjs'
export const cleanTitle = (title: string): string => title.replace(/[_|]+/g, ' ').trim()

export const toCamel = (s: string): string => {
  return s.replace(/([-_][a-z])/gi, $1 => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}
export const convertToTitle = (value: string, delimeter: string): string => {
  let words
  if (value.includes(delimeter)) {
    words = value.split(delimeter).map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
  } else {
    words = value.split(' ').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
  }
  return words.join(' ')
}
export const capitalize = (s: string) => (s.length ? s.charAt(0).toUpperCase() + s.slice(1).toLocaleLowerCase() : '')
const escape = (s: string) => s.replace(/./g, c => `\\${c}`)

export const convertToProperCase = (sentence: string, seps = ' _-/') => {
  if (sentence !== undefined) {
    let wordPattern = new RegExp(`[^${escape(seps)}]+`, 'g')
    return sentence.replace(wordPattern, capitalize)
  }
}

export const convertDetailValue = (sentence: string | undefined) => {
  if (sentence?.match(/^(\d{4})-(\d{2})-(\d{2})$/)) {
    return dayjs(sentence).format('MM/DD/YY')
  } else {
    if (sentence !== undefined) {
      sentence = sentence.replace(/[_]/g, ' ')
      sentence = sentence.replace(/[^-0-9a-zA-Z.()-/ ]/g, '')
      return convertToSentence(sentence)
    }
  }
}

export const convertToSentence = (sentence: string | undefined) => {
  if (sentence !== undefined) {
    return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase()
  }
}

export const convertCamelToSentence = (sentence: string | undefined) => {
  if (sentence !== undefined) {
    sentence = sentence.charAt(0).toLowerCase() + sentence.slice(1)
    const result = sentence.replace(/([A-Z])/g, ' $1')
    return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase()
  }
}

export const capitalizeAcronymInSentence = (sentence: string | undefined) => {
  if (sentence !== undefined) {
    sentence = sentence.replace(/\bid\b/gi, 'ID')
    sentence = sentence.replace(/dpci/gi, 'DPCI')
    sentence = sentence.replace(/tcin/gi, 'TCIN')
    sentence = sentence.replace(/upc/gi, 'UPC')
    sentence = sentence.replace(/pid/gi, 'PID')
    return sentence
  }
}

export const getFileNameWithoutExtension = (filePath: string | undefined) => {
  if (filePath !== undefined) {
    const path = require('path')
    const extension = path.extname(filePath)
    const fileNameWithoutExtension = path.basename(filePath, extension)
    return fileNameWithoutExtension
  }
}

export const includesCaseInsensitive = (value: string, searchTerm: string) => {
  return value.toUpperCase().includes(searchTerm.toUpperCase())
}

export const includesCaseSensitive = (value: string, searchTerm: string) => {
  return value.includes(searchTerm)
}

export const matchStrings = (str1: String, str2: String, ignoreCase: Boolean) => {
  if (ignoreCase) {
    return str1.toLocaleLowerCase() === str2.toLocaleLowerCase()
  }
  return str1 === str2
}

export const exactSearchHelper = (searchValue: string): string[] => {
  const regex = /"([^"]*)"/g //Detects pairs of double quotes
  const commaSeparated = searchValue.split(',')
  const handleQuotes = commaSeparated.map(term => {
    const quoteCount = (term.match(/"/g) || []).length //Checks to see if a single term has an odd number of quotes
    let adjustedTerm = quoteCount % 2 !== 0 ? term.replace(/["]+/g, '') : term // Strips all quotes from terms with an odd number of quotes
    return regex.exec(adjustedTerm) ? adjustedTerm.trim() : `"${adjustedTerm.trim()}"`
  })
  return handleQuotes
}
