import {
  AssetApi,
  AssetApiApiKeys,
  AssetDownloadApi,
  AssetDownloadApiApiKeys,
  AssetFileApi,
  AssetFileApiApiKeys,
  AssetTagApi,
  AssetTagApiApiKeys,
  AssetTaxonomyApi,
  AssetTaxonomyApiApiKeys,
  AssetUserApi,
  AssetUserApiApiKeys,
  CollectionApi,
  CollectionApiApiKeys,
  CollectionAssetsApi,
  CollectionAssetsApiApiKeys,
  CollectionDownloadApi,
  CollectionDownloadApiApiKeys,
  CollectionUserApi,
  CollectionUserApiApiKeys,
  TaxonomyApi,
  TaxonomyApiApiKeys,
  UserApi,
  UserApiApiKeys,
  HttpBearerAuth,
} from 'gallerydigitalassets-v1-client'
import {
  AssetUserApi as DigitalAssetUserApi,
  CollectionUserApi as DigitalCollectionUserApi,
  Configuration,
  CollectionApi as DigitalCollectionApi,
  CollectionAssetsApi as DigitalCollectionAssetsApi,
  CollectionFavoriteApi as DigitalCollectionFavoriteApi,
  ReportAssetApi as DigitalReportAssetApi,
  AssetTaxonomyApi as DigitalAssetTaxonomyApi,
} from '@galleryjs/api-digital-assets'
import { API_BASE_URL, STORAGE_KEYS } from 'config'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import jwt from 'jsonwebtoken'

const setAccessToken = () => {
  if (!window.location.hash) return
  let hash = window.location.hash.substring(1)
  if (hash.includes('service-redirect')) return
  // Find the access token returned in the implicit flow redirect_uri
  const accessToken: string = hash!
    .split('&')!
    .find(pair => pair!.includes('access_token'))!
    .split('=')[1]

  if (accessToken) {
    const decodedAccessToken = jwt.decode(accessToken) as unknown as any
    localStorage.setItem(STORAGE_KEYS.USER_TYPE, decodedAccessToken.dir)
    localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, accessToken)
  }
}

setAccessToken()

// Empty string token will lead to reauthenticating and saving new token in local storage
let accessToken: string = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN)!!
let contextRoot: string = localStorage.getItem(STORAGE_KEYS.CONTEXT_ROOT)!!
let apiKey: string = localStorage.getItem(STORAGE_KEYS.USER_TOKEN)!!
let clientAuth = new HttpBearerAuth()
clientAuth.accessToken = accessToken
const decodedAccessToken = jwt.decode(accessToken) as any

const configuration = new Configuration({
  basePath: `${API_BASE_URL}${contextRoot}`,
  apiKey: apiKey,
  accessToken: accessToken,
})
//TODO: replace with collectionsApi after rewrite
export const digitalCollectionApi = new DigitalCollectionApi(configuration)
export const digitalCollectionAssetsApi = new DigitalCollectionAssetsApi(configuration)

export const taxonomyApi = new TaxonomyApi(`${API_BASE_URL}${contextRoot}`)
export const assetTaxonomyApi = new AssetTaxonomyApi(`${API_BASE_URL}${contextRoot}`)
export const assetTagApi = new AssetTagApi(`${API_BASE_URL}${contextRoot}`)
export const userApi = new UserApi(`${API_BASE_URL}${contextRoot}`)
export const collectionApi = new CollectionApi(`${API_BASE_URL}${contextRoot}`)
export const collectionAssetApi = new CollectionAssetsApi(`${API_BASE_URL}${contextRoot}`)
export const collectionUserApi = new CollectionUserApi(`${API_BASE_URL}${contextRoot}`)
export const collectionDownloadApi = new CollectionDownloadApi(`${API_BASE_URL}${contextRoot}`)
export const assetApi = new AssetApi(`${API_BASE_URL}${contextRoot}`)
export const assetFileApi = new AssetFileApi(`${API_BASE_URL}${contextRoot}`)
export const assetUserApi = new AssetUserApi(`${API_BASE_URL}${contextRoot}`)
export const assetDownloadApi = new AssetDownloadApi(`${API_BASE_URL}${contextRoot}`)
export const digitalAssetUserApi = new DigitalAssetUserApi(configuration)
export const digitalCollectionUserApi = new DigitalCollectionUserApi(configuration)
export const digitalCollectionFavoriteApi = new DigitalCollectionFavoriteApi(configuration)
export const digitalReportAssetApi = new DigitalReportAssetApi(configuration)
export const digitalAssetTaxonomyApi = new DigitalAssetTaxonomyApi(configuration)

taxonomyApi.setDefaultAuthentication(clientAuth)
assetTaxonomyApi.setDefaultAuthentication(clientAuth)
assetTagApi.setDefaultAuthentication(clientAuth)
userApi.setDefaultAuthentication(clientAuth)
collectionApi.setDefaultAuthentication(clientAuth)
collectionAssetApi.setDefaultAuthentication(clientAuth)
collectionUserApi.setDefaultAuthentication(clientAuth)
collectionDownloadApi.setDefaultAuthentication(clientAuth)
assetApi.setDefaultAuthentication(clientAuth)
assetFileApi.setDefaultAuthentication(clientAuth)
assetUserApi.setDefaultAuthentication(clientAuth)
assetDownloadApi.setDefaultAuthentication(clientAuth)

taxonomyApi.setApiKey(TaxonomyApiApiKeys.query_key, apiKey)
assetTaxonomyApi.setApiKey(AssetTaxonomyApiApiKeys.query_key, apiKey)
assetTagApi.setApiKey(AssetTagApiApiKeys.query_key, apiKey)
userApi.setApiKey(UserApiApiKeys.query_key, apiKey)
collectionApi.setApiKey(CollectionApiApiKeys.query_key, apiKey)
collectionAssetApi.setApiKey(CollectionAssetsApiApiKeys.query_key, apiKey)
collectionUserApi.setApiKey(CollectionUserApiApiKeys.query_key, apiKey)
collectionDownloadApi.setApiKey(CollectionDownloadApiApiKeys.query_key, apiKey)
assetApi.setApiKey(AssetApiApiKeys.query_key, apiKey)
assetFileApi.setApiKey(AssetFileApiApiKeys.query_key, apiKey)
assetUserApi.setApiKey(AssetUserApiApiKeys.query_key, apiKey)
assetDownloadApi.setApiKey(AssetDownloadApiApiKeys.query_key, apiKey)

taxonomyApi.useQuerystring = true
assetTaxonomyApi.useQuerystring = true
assetTagApi.useQuerystring = true
userApi.useQuerystring = true
collectionApi.useQuerystring = true
collectionAssetApi.useQuerystring = true
collectionUserApi.useQuerystring = true
collectionDownloadApi.useQuerystring = true
assetApi.useQuerystring = true
assetFileApi.useQuerystring = true
assetUserApi.useQuerystring = true
assetDownloadApi.useQuerystring = true

// Axios gallery digital assets Client
export const galleryAxiosClient = axios.create({
  baseURL: `${API_BASE_URL}${contextRoot}`,
  withCredentials: false,
})
galleryAxiosClient.interceptors.request.use(config => {
  config.headers = {
    Authorization: `Bearer ${accessToken}`,
    'X-Api-Id': uuidv4(),
    startDate: new Date().toDateString(),
    'Content-Type': 'application/json',
    accept: 'application/json',
    'X-Tgt-LANID': decodedAccessToken.username,
  }
  config.params = { ...config.params, key: apiKey }
  return config
})
galleryAxiosClient.interceptors.response.use(
  rsp => rsp,
  err => {
    if (axios.isCancel(err)) {
      console.log('Request cancel', err.message)
    } else {
      console.error(err)
      return Promise.reject(err)
    }
  }
)

// Axios Search Client
export const axiosSearchClient = axios.create({
  withCredentials: true,
})
axiosSearchClient.interceptors.request.use(config => {
  config.headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
    accept: 'application/json',
    'X-Tgt-LANID': decodedAccessToken.username,
  }
  config.params = { ...config.params, key: apiKey }
  return config
})
axiosSearchClient.interceptors.response.use(
  rsp => rsp,
  err => {
    if (axios.isCancel(err)) {
      console.log('Request cancel', err.message)
    } else {
      console.error(err)
      return Promise.reject(err)
    }
  }
)

// Axios gallery asset activity Client
export const axiosAssetActivityClient = axios.create({
  withCredentials: true,
})
axiosAssetActivityClient.interceptors.request.use(config => {
  config.headers = {
    Authorization: `Bearer ${accessToken}`,
    accept: 'application/json',
    'X-Tgt-LANID': decodedAccessToken.username,
  }
  config.params = { ...config.params, key: apiKey }
  return config
})
axiosAssetActivityClient.interceptors.response.use(
  rsp => rsp,
  err => {
    if (axios.isCancel(err)) {
      console.log('Request cancel', err.message)
    } else {
      console.error(err)
      return Promise.reject(err)
    }
  }
)
