export const MODAL_TYPES = {
  ADD_COLLECTION: 'add-collection',
  CREATE_COLLECTION: 'create-collection',
  ADD_ASSET: 'add-asset',
  DELETE_FILE: 'delete-file',
  DELETE_ASSET: 'delete-asset',
  EDIT_ASSET: 'edit-asset',
  EDIT_ASSET_USER: 'edit-asset-user',
  EDIT_COLLECTION_USER: 'edit-collection-user',
  ID_LIST_SEARCH: 'id-list-search',
  COLLAGE_LIGHTBOX: 'collage-lightbox',
  EDIT_COLLECTION: 'edit-collection',
  DELETE_COLLECTION: 'delete-collection',
  HELP: 'help-modal',
  ASSET_ACTIVITY: 'asset-activity',
  ASSET_REPORT: 'asset-report',
}

export const COOKIE_KEYS = {
  INITIAL_LOCATION: 'gallery_initial_location',
  REDIRECT_SERVICE: 'redirect_service',
}

export const STORAGE_KEYS = {
  ACCESS_TOKEN: 'gallery_access_token',
  DISPLAY_USER_NAME: 'displayname',
  DISPLAY_COMPANY_NAME: 'company',
  USER_TYPE: 'USER_TYPE',
  USER_TOKEN: 'user_token',
  TGT_LAN_ID: 'tgt_lan_id',
  CONTEXT_ROOT: 'context_root',
}

export const BREAD_CRUMB_VARS = {
  GROUPED_COLLECTIONS: 'Grouped Collections',
  COLLECTIONS: 'Collections',
  COLLECTION_ROUTE: '/collections',
  NUM_CRUMBS: 4,
}

export const API_BASE_URL = `${document.location.origin}/api`
export const DEFAULT_IMAGE_URL = `${process.env.PUBLIC_URL}/Images/SubcategoryImages/no_image_thumbnail.png`

export const DEFAULT_FILTERS_ARRAY = []
