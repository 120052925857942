import { createSelector } from 'reselect'
import { RootState } from 'store'

export const assetSelector = createSelector(
  (state: RootState) => state.asset,
  state => state
)
export const assetDetailPageAssetSelector = createSelector(assetSelector, state => state.assetDetailPageAsset)
export const linkedAssetSelector = createSelector(assetDetailPageAssetSelector, state => state?.linkedAssets)
export const assetUsersSelector = createSelector(assetSelector, state => state.assetUsers)
export const newFilesStatusValuesSelector = createSelector(assetSelector, state => state.assetCreationFileValues)
export const assetDetailPageStatusSelector = createSelector(assetSelector, state => state.assetDetailPageStatus)
export const userAssetPermissionsSelector = createSelector(assetSelector, state => state.userAssetPermissions)
export const assetActivitySelector = createSelector(assetSelector, state => state.assetActivity)
export const assetActivityIsLoadingSelector = createSelector(assetSelector, state => state.assetActivityIsLoading)
export const assetReportsSelector = createSelector(assetSelector, state => state.assetReports)
export const assetReportsIsLoadingSelector = createSelector(assetSelector, state => state.assetReportsIsLoading)
