import { useState, useEffect } from 'react'
import { filter, isEqual, clone } from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import qs from 'qs'
import {
  searchAssetsReducer,
  setCurrentSearchTab,
  setCurrentSearchValue,
  getFiltersReducer,
  setFiltersAreLoading,
  clearSearchPageAssets,
  clearSearchCriteria,
  setSearchPageAssetsPaginationIsLoading,
  setSearchAPIError,
  setExactSearch,
  setSelfAssetToggle,
  setSearchChange,
} from './slice'
import { IFilterAction, IFilterChip, IFilterItemUI, IFilterValueUI, searchSizeSelector } from 'store'
import {
  currentSearchTabSelector,
  searchPageAssetsSelector,
  searchPageAssetsIsLoadingSelector,
  currentSearchValueSelector,
  searchFiltersSelector,
  searchFiltersAreLoadingSelector,
  searchCriteriaSelector,
  searchAPIErrorSelector,
  exactSearchSelector,
  searchSelfAssetToggleSelector,
  searchChangeSelector,
} from './selectors'

import { includesCaseInsensitive } from 'utils/string'
import { ISearchCriteria } from './types'
import { useEnv } from '@praxis/component-runtime-env'
import { findFunkyFiltersByKey } from './utils'

export const useSearch = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { validFilterKeys, customSearchFilters, funkyFilterKeys, searchServer, searchSecretKey } = useEnv()
  // Constant to be used to prevent a new array object from initilizing every time by default for searchCriteria.f
  const SEARCH_API_KEY = searchSecretKey || process.env.REACT_APP_SEARCH_SECRET_KEY

  //// SELECTORS ////
  const searchPageAssets = useSelector(searchPageAssetsSelector)
  const searchPageAssetsAreLoading = useSelector(searchPageAssetsIsLoadingSelector)
  const currentSearchValue = useSelector(currentSearchValueSelector)
  const currentSearchTab = useSelector(currentSearchTabSelector)
  const searchFilters = useSelector(searchFiltersSelector)
  const searchFiltersAreLoading = useSelector(searchFiltersAreLoadingSelector)
  const searchCriteria = useSelector(searchCriteriaSelector)
  const searchSize = useSelector(searchSizeSelector)
  const searchError = useSelector(searchAPIErrorSelector)
  const exactSearch = useSelector(exactSearchSelector)
  const selfAssetToggle = useSelector(searchSelfAssetToggleSelector)
  const searchChange = useSelector(searchChangeSelector)

  //// HOOK STATE ////
  const [isHomePage, setIsHomePage] = useState<boolean>(false)
  const [isSearchPage, setIsSearchPage] = useState<boolean>(false)

  //only for reading and updating search state for hard refresh and deeplinking

  const querySearchCriteria = qs.parse(location.search, { ignoreQueryPrefix: true })

  useEffect(() => {
    let page = location.pathname.trim().split('/')[1]
    setIsHomePage(page === '')
    setIsSearchPage(page === 'search')

    if (!includesCaseInsensitive(location.pathname, 'search')) {
      dispatch(setCurrentSearchValue(''))
      dispatch(setCurrentSearchTab('All'))
    }
  }, [location])

  // values from customSearchFilters that, instead of searching by key in value, we seek the value in both tag keys and value
  // This is a result of custom UI filter items that do not correspond to asset tag-value pairs in DB
  const filterValuesToSeekInTagKeyAndValue = findFunkyFiltersByKey(funkyFilterKeys, customSearchFilters)

  const searchAssets = (
    query: string,
    page: number = 0,
    filters: { [key: string]: string[] },
    size: number = searchSize,
    sort: string[] = [],
    validFilterKeys: string[],
    currentSearchTab: string,
    taxonomy?: string
  ) => {
    const payload = {
      query,
      page,
      filters,
      size,
      sort,
      taxonomy,
      validFilterKeys,
      currentSearchTab,
      funkyFilters: filterValuesToSeekInTagKeyAndValue,
      server: searchServer,
      key: SEARCH_API_KEY,
    }
    dispatch(searchAssetsReducer(payload))
  }

  const dispatchSearch = (searchCriteria: ISearchCriteria) => {
    setSearchError(false)
    if (searchCriteria && searchCriteria.q && searchCriteria.q.includes('&')) {
      searchCriteria.q = searchCriteria.q.replace(/&/g, '%26')
    }
    const newQuery = qs.stringify(searchCriteria, { encode: false })
    const searchQuery = `?${newQuery}`
    searchAssets(
      `${searchCriteria.q}`,
      searchCriteria.p,
      searchCriteria.f!,
      searchCriteria.size,
      [],
      validFilterKeys,
      currentSearchTab,
      searchCriteria.t
    )
    navigate(`/search${searchQuery}`, { replace: true })
  }

  const infiniteScrollMethod = () => {
    dispatchSearch({ ...searchCriteria, p: searchCriteria.p + 1 })
  }

  const setSearchTab = (tabValue: string) => {
    dispatch(setCurrentSearchTab(tabValue))
  }

  const setSearchValue = (searchValue: string) => {
    dispatch(setCurrentSearchValue(searchValue))
  }

  const activateExactSearch = (exactSearch: boolean) => {
    dispatch(setExactSearch(exactSearch))
  }

  const activateSelfAssetCreatedSearch = (isSelfAssetToggle: boolean) => {
    dispatch(setSelfAssetToggle(isSelfAssetToggle))
  }

  const activateSearchChange = (searchChange: boolean) => {
    dispatch(setSearchChange(searchChange))
  }

  const setFiltersLoading = () => {
    dispatch(setFiltersAreLoading(null))
  }

  const setSearchPageAssetsLoading = (loading: boolean) => {
    dispatch(setSearchPageAssetsPaginationIsLoading(loading))
  }

  const clearSearchPage = () => {
    dispatch(clearSearchPageAssets(null))
  }

  const clearSearchCriteriaInStore = () => {
    dispatch(clearSearchCriteria(null))
  }

  const setSearchError = (error: boolean) => {
    dispatch(setSearchAPIError(error))
  }

  const clearStore = () => {
    setFiltersLoading()
    clearSearchPage()
    clearSearchCriteriaInStore()
    setSearchError(false)
  }

  //// GLOBAL FILTERS LOGIC ////
  const [currentFilterChips, setCurrentFilterChips] = useState<IFilterChip>(searchCriteria?.f!!)

  const getFilters = async (searchQuery: string, tagFilterQuery?: string) => {
    const payload = {
      searchQuery,
      // tagFilterQuery,
      supportedFilters: validFilterKeys,
      server: searchServer,
      key: SEARCH_API_KEY,
      currentSearchTab,
    }
    dispatch(getFiltersReducer(payload))
  }

  // actions on selecting / deselecting checkboxes
  const selectFilter = (chosenFilterKey: string, chosenFilterName: string) => {
    const currentFilters =
      searchCriteria.f && searchCriteria.f![chosenFilterKey!] ? searchCriteria.f![chosenFilterKey!] : []
    const filterInList = currentFilters.find(fltr => fltr === chosenFilterName)
    if (filterInList) {
      updateSearchCriteriaFilters(IFilterAction.Remove, chosenFilterKey, chosenFilterName)
    } else {
      updateSearchCriteriaFilters(IFilterAction.Add, chosenFilterKey, chosenFilterName)
    }
  }

  // updates searchCriteriaFilters, the source of truth for filters
  const updateSearchCriteriaFilters = (action: IFilterAction, filterKey?: string, filterValue?: string) => {
    let updatedFilters: IFilterChip = { ...searchCriteria.f!! }

    //Does this property exist
    const propIsEmpty = !Object.prototype.hasOwnProperty.call(updatedFilters, filterKey!)

    let filterChip: IFilterChip = {}
    let currentArray: string[] = []
    switch (action) {
      case IFilterAction.Add:
        // add criteria filter to search
        currentArray = propIsEmpty || filterKey === 'original_upload_date' ? [] : clone(updatedFilters[filterKey!])
        currentArray.push(filterValue!)
        updatedFilters[filterKey!] = currentArray
        break
      case IFilterAction.Remove:
        // remove filter from search criteria
        //Get all the filter sections
        Object.keys(updatedFilters).forEach((searchFilter: string) => {
          if (searchFilter === 'original_upload_date' && filterKey === 'original_upload_date') {
            delete updatedFilters[searchFilter]
          } else {
            const newFilters = updatedFilters[searchFilter].filter(uf => uf !== filterValue)
            if (newFilters.length > 0) {
              filterChip[searchFilter] = updatedFilters[searchFilter].filter(uf => uf !== filterValue)
            }
          }
        })
        updatedFilters = filterChip
        break
      case IFilterAction.RemoveAll:
        updatedFilters = {}
    }
    clearSearchPage()
    dispatchSearch({
      q: searchCriteria.q,
      p: 0,
      f: { ...updatedFilters },
      t: searchCriteria.t,
    })
  }

  // when search criteria filter is updated update the filter chips
  useEffect(() => {
    if (!isEqual(currentFilterChips, searchCriteria.f!!)) {
      setCurrentFilterChips(searchCriteria.f!!)
    }
  }, [searchCriteria.f])

  return {
    querySearchCriteria,
    searchError,
    searchSize,
    isHomePage,
    isSearchPage,
    searchPageAssets,
    searchPageAssetsAreLoading,
    currentSearchValue,
    exactSearch,
    selfAssetToggle,
    activateExactSearch,
    activateSelfAssetCreatedSearch,
    activateSearchChange,
    setSearchValue,
    setSearchError,
    setSearchPageAssetsLoading,
    currentSearchTab,
    setSearchTab,
    searchAssets,
    infiniteScrollMethod,
    getFilters,
    searchFilters,
    searchFiltersAreLoading,
    selectFilter,
    updateSearchCriteriaFilters,
    currentFilterChips,
    setFiltersLoading,
    dispatchSearch,
    clearStore,
    searchCriteria,
    searchChange,
  }
}

/**
 * @param  {IFilterItem} props
 * @description Custom hook used to manage the data for each FilterItem Component.
 */
/// make sure selected is initialized as false
export const useFilter = (filterItemUI?: IFilterItemUI) => {
  const searchCriteria = useSelector(searchCriteriaSelector)
  // Hook used for adding filters
  const filterCategory = filterItemUI?.filterKeyUI
  const noSelectedFilters = filterItemUI?.filterValueUI.filter(filterValue => {
    return !!filterValue.filterValue.value && filterValue.filterValue.value.toLowerCase() !== 'null'
  })
  const [selectedFilters, updateSelectedFilters] = useState<IFilterValueUI[]>(noSelectedFilters!!)
  // current state of checkboxes filtered by input box
  const [filteredCheckboxes, setFilteredCheckboxes] = useState<IFilterValueUI[]>(selectedFilters)
  const [filterInput, setFilterInput] = useState<string>('')
  const [numberOfFilters, setNumberOfFilters] = useState<number>(0)

  // every time the search criteria is updated update the filter side bar accordingly
  useEffect(() => {
    const relevantSearchFilters: string[] =
      searchCriteria.f && searchCriteria.f![filterCategory!] ? searchCriteria.f![filterCategory!] : []
    if (Object.keys(relevantSearchFilters).length === 0) {
      updateSelectedFilters(noSelectedFilters!!)
    } else {
      const updatedFilters = selectedFilters?.map((filterValueUI: IFilterValueUI) => {
        const selectedItem = relevantSearchFilters.find(filterItem => filterValueUI.filterValue.value === filterItem)
        return selectedItem ? { ...filterValueUI, ...{ selected: true } } : { ...filterValueUI, ...{ selected: false } }
      })
      updateSelectedFilters(updatedFilters)
    }
  }, [searchCriteria?.f])

  // allows input box to filter checkboxes
  useEffect(() => {
    if (filterInput) {
      setFilteredCheckboxes(
        filter(selectedFilters, function (filterValueUI: IFilterValueUI) {
          return includesCaseInsensitive(filterValueUI.filterValue.value, filterInput)
        })
      )
    } else setFilteredCheckboxes(selectedFilters)
  }, [filterInput, selectedFilters])

  // updates number of files for filter avatar
  useEffect(() => {
    const checkedFilters = filter(selectedFilters, 'selected')
    setNumberOfFilters(checkedFilters.length)
  }, [selectedFilters])

  return {
    setFilterInput,
    filterInput,
    selectedFilters,
    filteredCheckboxes,
    numberOfFilters,
  }
}
