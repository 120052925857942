import { createSelector } from 'reselect'
import { RootState } from 'store'

export const searchSelector = createSelector(
  (state: RootState) => state.search,
  state => state
)
export const currentSearchValueSelector = createSelector(searchSelector, state => state.currentSearchValue)
export const currentSearchTabSelector = createSelector(searchSelector, state => state.currentSearchTab)
export const searchPageAssetsSelector = createSelector(searchSelector, state => state.searchPageAssets)
export const searchAPIErrorSelector = createSelector(searchSelector, state => state.searchAPIError)
export const searchPageAssetsIsLoadingSelector = createSelector(
  searchSelector,
  state => state.searchPageAssets.paginationIsLoading
)
export const searchFiltersSelector = createSelector(searchSelector, state => state.searchFilters.filterValues)
export const searchFiltersAreLoadingSelector = createSelector(searchSelector, state => state.searchFilters.loading)
export const searchCriteriaSelector = createSelector(searchSelector, state => state.searchCriteria)
export const exactSearchSelector = createSelector(searchSelector, state => state.exactSearch)
export const searchSelfAssetToggleSelector = createSelector(searchSelector, state => state.selfAssetToggle)
export const searchChangeSelector = createSelector(searchSelector, state => state.searchChange)
