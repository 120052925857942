import { some } from 'lodash'
import { all, takeLatest, call, put, select } from 'redux-saga/effects'
import {
  IAsset,
  IFilterItem,
  IFilterItemUI,
  IFilterValue,
  IFilterValueUI,
  ISearchPageAssets,
  ISearchSort,
  searchPageAssetsSelector,
} from 'store'
import { processError } from 'store/utils'
import { deepCompareObjects } from 'utils/object'
import { exactSearchHelper } from 'utils/string'
import { getFiltersApi, searchAssetsApi } from './api'
import {
  exactSearchSelector,
  searchCriteriaSelector,
  searchSelfAssetToggleSelector,
  searchChangeSelector,
} from './selectors'
import {
  searchAssetsReducer,
  setSearchPageAssets,
  appendSearchPageAssets,
  setFilters,
  getFiltersReducer,
  setSearchCriteriaReducer,
  setFiltersAreLoading,
  setSearchPageAssetsPaginationIsLoading,
  clearSearchPageAssets,
  setSearchAPIError,
} from './slice'
import { ICustomFilterValue, ISearchCriteria } from './types'
import { STORAGE_KEYS } from 'config'

function* getSearchPageAssetsSaga(data: any) {
  const searchCriteria: ISearchCriteria = yield select(searchCriteriaSelector)
  const exactSearch: boolean = yield select(exactSearchSelector)
  const selfAssetToggle: boolean = yield select(searchSelfAssetToggleSelector)
  const searchChange: boolean = yield select(searchChangeSelector)
  const searchPageAssets: ISearchPageAssets = yield select(searchPageAssetsSelector)
  const searchSort = searchPageAssets.sort
  const initialSearchCriteria = { ...searchCriteria }
  const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!! || ''
  const userList = selfAssetToggle ? [xTgtLanId] : []

  if (!deepCompareObjects(searchCriteria, data.payload)) {
    const {
      query,
      page,
      filters,
      size,
      sort,
      // searchQuery,
      validFilterKeys,
      currentSearchTab,
      taxonomy,
      // Values to seek in both asset tag key and value
      funkyFilters,
      server,
      key,
    } = data.payload
    try {
      const searchQuery = query.includes('%26') ? query.replace(/%26/g, '&') : query
      const searchCriteriaPayload = {
        q: searchQuery,
        p: page,
        f: filters,
        t: taxonomy,
        size: size,
        sort: sort,
        server: server,
        key: key,
      }
      yield put(setSearchCriteriaReducer(searchCriteriaPayload))
      // call filters api when taxonommy is empty on searching empty query after login, i.e., for first search with empty query

      if (query !== initialSearchCriteria.q || taxonomy !== initialSearchCriteria.t || taxonomy === '') {
        // load new filters for the updated search just happened
        const filtersPayload = {
          // searchQuery,
          validFilterKeys,
          server: server,
          key: key,
          q: searchQuery,
          f: filters,
          currentSearchTab,
        }
        yield put(setFiltersAreLoading(true))
        yield put(getFiltersReducer(filtersPayload))
      }

      yield put(setSearchPageAssetsPaginationIsLoading(true))

      if (page === 0) yield put(clearSearchPageAssets(null))
      let newQuery = exactSearch ? exactSearchHelper(searchQuery).join(',') : searchQuery
      const response: { searchAssets: IAsset[]; totalCount: number; sort: ISearchSort[] } = yield call(
        searchAssetsApi,
        newQuery,
        page,
        filters,
        size,
        sort,
        funkyFilters,
        server,
        key,
        taxonomy,
        searchSort,
        userList,
        searchChange
      )
      if (page > 0) {
        yield put(
          appendSearchPageAssets({
            assets: response.searchAssets,
            page: data.payload.page,
            total: response.totalCount,
            sort: response.sort,
          })
        )
        yield put(setSearchPageAssetsPaginationIsLoading(false))
      } else {
        yield put(
          setSearchPageAssets({ assets: response.searchAssets, total: response.totalCount, sort: response.sort })
        )
      }
    } catch (error) {
      if (error instanceof Error) {
        yield call(processError, error, error.message)
      } else {
        console.log(error)
      }
      yield put(setSearchCriteriaReducer(initialSearchCriteria))
      yield put(setSearchPageAssetsPaginationIsLoading(false))
      yield put(setSearchAPIError(true))
    }
  } else {
    console.log('preventing another search call with same params')
  }
}

function* getSearchFiltersSaga(data: any) {
  const { validFilterKeys, currentSearchTab, server, key, q, f } = data.payload
  let query = q.includes('%26') ? q.replace(/%26/g, '&') : q
  try {
    const response: IFilterItem[] = yield call(
      getFiltersApi,
      // searchQuery,
      currentSearchTab,
      validFilterKeys,
      server,
      key,
      query,
      f
    )
    if (some(response)) {
      const filterItemUiArray = response.map((filterItem: IFilterItem): IFilterItemUI => {
        filterItem.value.sort((filterValueA: IFilterValue, filterValueB: IFilterValue) =>
          filterValueA.filterValue > filterValueB.filterValue ? 1 : -1
        )
        const filterValueUI: IFilterValueUI[] = filterItem.value.map((value: any): IFilterValueUI => {
          const filterUI: ICustomFilterValue = { value: value.filterValue, displayValue: null }
          return { ...{ selected: false }, ...{ filterValue: filterUI } }
        })
        return { ...{ filterValueUI: [...filterValueUI] }, ...{ filterKeyUI: filterItem.key } }
      })
      yield put(setFilters(filterItemUiArray))
    } else yield put(setFilters([]))
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

export default function* watchTaxonomySagas() {
  yield all([
    takeLatest(searchAssetsReducer.type, getSearchPageAssetsSaga),
    takeLatest(getFiltersReducer.type, getSearchFiltersSaga),
  ])
}
